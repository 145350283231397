$color-1: #1d1f20;
$color-2: #de1303;
$color-3: #ffa299;
$color-4: #166379;
$color-5: #fff;
$color-6: #bbb;
$color-7: #949494;
$font-family-normal: -apple-system, system-ui, blinkmacsystemfont, 'Segoe UI', roboto, oxygen, ubuntu, 'Helvetica Neue',
    arial, sans-serif;
$font-family-mono: consolas, 'Liberation Mono', courier, monospace;
$background-color-1: #fff;
$background-color-2: #fafafa;
$background-color-3: #222;
$background-color-4: white;
$background-color-5: #6d6d6d;
$checkbox-color-1: #fbad41;
$success-color-1: #0aa937;
$success-color-2: #038127;
$dark-mark-color-1: #dadada;
$fail-color-1: #c44d0e;
$border-color-1: $checkbox-color-1;
$border-color-2: #666;
$border-color-3: #ffa299;
$content-border-color-1: #e0e0e0;

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes fill {
    100% {
        transform: scale(1);
    }
}

@keyframes fillfail {
    100% {
        box-shadow: inset 0 0 0 30px $fail-color-1;
    }
}

@keyframes fillfail-offlabel {
    100% {
        box-shadow: inset 0 0 0 30px $color-1;
    }
}

@keyframes fillfail-offlabel-dark {
    100% {
        box-shadow: inset 0 0 0 30px #fff;
    }
}

@keyframes scale-up-center {
    0% {
        transform: scale(0.01);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes unspin {
    40% {
        stroke-width: 1px;
        stroke-linecap: square;
        stroke-dashoffset: 192;
    }

    100% {
        stroke-width: 0;
    }
}

html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    background-color: $background-color-1;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    line-height: 17px;
    color: $color-1;
    font-family: $font-family-normal;
    font-size: 14px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
}

h1 {
    margin: 16px 0;
    text-align: center;
    line-height: 1.25;
    color: $color-1;
    font-size: 16px;
    font-weight: 700;
}

p {
    margin: 8px 0;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}

#content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $content-border-color-1;
    background-color: $background-color-2;
    height: 63px;
    user-select: none;
}

#challenge-stage {
    display: flex;
}

#branding {
    display: flex;
    flex-direction: column;
    margin: 0 16px 0 0;
    text-align: right;
}

#spinner-icon {
    display: flex;
    margin-right: 8px;
    width: 30px;
    height: 30px;
    animation: rotate 5s linear infinite;
}

#fail-icon {
    display: flex;
    margin-right: 8px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 $fail-color-1;
    width: 30px;
    height: 30px;
    animation:
        fillfail 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
    stroke-width: 6px;
    stroke: #f8f8f8;
    stroke-miterlimit: 10;
}

#success-icon {
    display: flex;
    margin-right: 8px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 $success-color-2;
    width: 30px;
    height: 30px;
    animation: scale-up-center 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    stroke-width: 6px;
    stroke: #f8f8f8;
    stroke-miterlimit: 10;

    .p1 {
        stroke-dasharray: 242;
        stroke-dashoffset: 242;
        box-shadow: inset 0 0 0 $success-color-2;
        animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        animation-delay: 0.5s;
    }
}

.success-circle {
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $success-color-2;
    fill: $success-color-2;
}

.theme-dark {
    #success-icon {
        box-shadow: inset 0 0 0 $success-color-1;

        .p1 {
            box-shadow: inset 0 0 0 $success-color-1;
        }
    }

    .success-circle {
        stroke: $success-color-1;
        fill: $success-color-1;
    }

    #challenge-overlay {
        background-color: $background-color-3;
    }

    #challenge-overlay,
    #challenge-error-text {
        color: $color-3;

        a {
            color: $color-6;

            &:visited,
            &:link {
                color: $color-6;
            }

            &:hover,
            &:active,
            &:focus {
                color: $color-7;
            }
        }
    }

    .cb-lb {
        .cb-i {
            border: 2px solid $dark-mark-color-1;
            background-color: $background-color-3;
        }

        input {
            &:focus,
            &:active {
                ~ {
                    .cb-i {
                        border: 2px solid $checkbox-color-1;
                    }
                }
            }

            &:checked {
                ~ {
                    .cb-i {
                        background-color: $background-color-5;

                        &::after {
                            border-color: $border-color-1;
                        }
                    }
                }
            }
        }
    }

    .offlabel {
        #fail-icon {
            box-shadow: inset 0 0 0 #fff;
            animation:
                fillfail-offlabel-dark 0.4s ease-in-out 0.4s forwards,
                scale 0.3s ease-in-out 0.9s both;
        }
    }

    h1 {
        color: $color-5;
    }

    #challenge-error-title {
        color: $color-3;

        a {
            color: $color-6;

            &:visited,
            &:link {
                color: $color-6;
            }

            &:hover,
            &:active,
            &:focus {
                color: $color-7;
            }
        }
    }

    #terms {
        color: $color-6;

        a {
            color: $color-6;

            &:visited,
            &:link {
                color: $color-6;
            }

            &:hover,
            &:active,
            &:focus {
                color: $color-7;
            }
        }
    }

    #content {
        border-color: $border-color-2;
        background-color: $background-color-3;
    }

    #qr {
        fill: rgb(243 128 32);
    }

    .logo-text {
        fill: #fff;
    }

    #fr-helper-link,
    #fr-helper-loop-link {
        color: $color-6;

        &:visited,
        &:link {
            color: $color-6;
        }

        &:active,
        &:hover,
        &:focus {
            color: $color-7;
        }
    }

    #timeout-refresh-link,
    #expired-refresh-link {
        color: $color-6;

        &:visited,
        &:link {
            color: $color-6;
        }

        &:active,
        &:hover,
        &:focus {
            color: $color-7;
        }
    }

    .overlay {
        border-color: $border-color-3;
        color: $color-3;
    }
}

#challenge-error {
    margin: 0 8px;
}

#challenge-overlay {
    position: absolute;
    top: 0;
    z-index: 9999;
    background-color: $background-color-2;
}

#challenge-overlay,
#challenge-error-text {
    text-align: center;
    line-height: 10px;
    color: $color-2;
    font-size: 9px;

    a {
        color: $color-1;

        &:visited,
        &:link {
            color: $color-1;
        }

        &:active,
        &:hover,
        &:focus {
            color: $color-4;
        }
    }
}

#logo {
    margin-bottom: 1px;
    height: 26px;
}

.failure-circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: $fail-color-1;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.failure-cross {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.cb-c {
    display: flex;
    align-items: center;
    margin-left: 11px;
    cursor: pointer;
    text-align: left;
}

.cb-lb {
    cursor: pointer;
    padding-left: 37px;

    input {
        position: absolute;
        top: 20px;
        left: 18px;
        opacity: 0;
        z-index: 9999;
        cursor: pointer;
        width: 24px;
        height: 24px;

        &:active {
            ~ {
                .cb-i {
                    border: 2px solid $fail-color-1;
                }

                span.cb-lb-t {
                    text-decoration: underline;
                }
            }
        }

        &:focus {
            ~ {
                .cb-i {
                    border: 2px solid $fail-color-1;
                }

                span.cb-lb-t {
                    text-decoration: underline;
                }
            }
        }

        &:checked {
            ~ {
                .cb-i {
                    transform: rotate(0deg) scale(1);
                    opacity: 1;
                    border-radius: 5px;
                    background-color: $background-color-4;

                    &::after {
                        top: 3px;
                        left: 8px;
                        transform: rotate(45deg) scale(1);
                        border: solid $fail-color-1;
                        border-width: 0 4px 4px 0;
                        border-radius: 0;
                        width: 6px;
                        height: 12px;
                    }
                }
            }
        }
    }

    .cb-i {
        position: absolute;
        top: 20px;
        left: 18px;
        transition: all 0.1s ease-in;
        z-index: 9998;
        border: 2px solid $background-color-5;
        border-radius: 3px;
        background: #fff;
        width: 24px;
        height: 24px;
        animation: scale-up-center 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

        &::after {
            position: absolute;
            border-radius: 5px;
            content: '';
        }
    }
}

.size-compact {
    font-size: 13px;

    .cb-lb {
        .cb-i {
            left: 15px;
        }

        input {
            left: 15px;
        }
    }

    #content {
        display: flex;
        flex-flow: column nowrap;
        place-content: center center;
        align-items: center;
        height: 118px;
    }

    .link-spacer {
        margin-right: 3px;
        margin-left: 3px;
    }

    .cb-c {
        text-align: left;
    }

    #logo {
        margin-top: 5px;
        margin-bottom: 0;
        height: 22px;
    }

    .cb-container {
        margin-top: 3px;
        margin-left: 0;
    }

    #branding {
        display: flex;
        flex-flow: row-reverse wrap;
        place-content: center flex-start;
        align-items: center;
        margin: 5px 16px 0;
        padding-right: 0;
        text-align: right;
    }

    #terms {
        text-align: right;
    }

    #qr {
        text-align: center;
    }

    #challenge-error-title {
        margin-top: 3px;
        width: auto;
    }

    #fail {
        display: flex;
        flex-flow: row nowrap;
        place-content: center space-evenly;
        align-items: center;
        visibility: visible;
        line-height: 13px;
        font-size: 11px;
    }

    #fail-icon {
        margin-right: 4px;
        width: 25px;
        height: 25px;
    }

    #timeout,
    #expired {
        margin-top: 9px;
        margin-left: 11px;
    }

    #challenge-error {
        margin: 0 2px;
    }
}

.cb-lb-t {
    display: flex;
    flex-flow: row-reverse nowrap;
    place-content: center flex-end;
    align-items: center;
    padding-left: 2px;
}

.rtl {
    .cb-lb-t {
        padding-right: 2px;
        padding-left: 0;
    }

    #success-icon {
        left: 255px;
        margin-left: 8px;
    }

    #fail-icon {
        left: 255px;
        margin-left: 8px;
    }

    #spinner-icon {
        left: 255px;
        margin-left: 8px;
    }

    #timeout-icon,
    #expired-icon {
        left: 255px;
        margin-left: 8px;
    }

    #branding {
        margin: 0 0 0 16px;
        padding-right: 0;
        padding-left: 0;
        width: 90px;
        text-align: center;
    }

    .size-compact {
        #branding {
            padding-right: 0;
            padding-left: 0;
            text-align: center;
        }

        #terms {
            text-align: center;
        }

        #cf-stage {
            padding-right: 48px;
        }

        #success-icon {
            left: 86px;
        }

        #fail-icon {
            left: 86px;
        }

        #spinner-icon {
            left: 86px;
        }

        #timeout-icon,
        #expired-icon {
            left: 86px;
        }

        #expired,
        #timeout {
            margin-top: 0;
            margin-left: 0;
        }
    }

    .cb-lb {
        padding-right: 37px;
        padding-left: 0;
        text-align: right;

        input {
            right: 18px;

            &:checked {
                ~ {
                    .cb-i {
                        &::after {
                            right: 8px;
                        }
                    }
                }
            }
        }

        .cb-i {
            right: 18px;
        }
    }

    .cb-c {
        margin-right: 11px;
        margin-left: 0;
        text-align: right;
    }

    .cb-container {
        margin-left: 0;
    }
}

#terms {
    display: flex;
    justify-content: space-evenly;
    line-height: 10px;
    color: $color-1;
    font-size: 8px;
    font-style: normal;

    a {
        text-decoration: underline;
        line-height: 10px;
        color: $color-1;
        font-size: 8px;
        font-weight: 400;
        font-style: normal;

        &:link,
        &:visited {
            text-decoration: underline;
            line-height: 10px;
            color: $color-1;
            font-size: 8px;
            font-weight: 400;
            font-style: normal;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
            color: $color-4;
        }
    }
}

#challenge-error-title {
    position: absolute;
    top: 0;
    margin: 5px 0;
    width: 200px;
    height: 55px;
    color: $color-2;
    font-size: 11px;

    a {
        color: $color-1;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
            color: $color-4;
        }

        &:link,
        &:visited {
            color: $color-1;
        }
    }

    .icon-wrapper {
        display: none;
    }
}

.unspun {
    .circle {
        animation: unspin 0.7s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
}

.circle {
    stroke-width: 3px;
    stroke-linecap: round;
    stroke: $success-color-2;
    stroke-dasharray: 0, 100, 0;
    stroke-dashoffset: 200;
    stroke-miterlimit: 1;
    stroke-linejoin: round;
}

.main-wrapper {
    border-spacing: 0;
}

.p1 {
    fill: none;
    stroke: #fff;
}

.timeout-p1,
.expired-p1 {
    fill: none;
    stroke: #fff;
}

.offlabel {
    .circle {
        stroke: $color-1;
    }

    .success-circle {
        stroke: $color-1;
        fill: $color-1;
    }

    .failure-circle {
        stroke: $color-1;
    }

    #fail-icon {
        box-shadow: inset 0 0 0 $color-1;
        animation:
            fillfail-offlabel 0.4s ease-in-out 0.4s forwards,
            scale 0.3s ease-in-out 0.9s both;
    }
}

.theme-dark.offlabel {
    .circle {
        stroke: #fff;
    }

    .success-circle {
        stroke: #fff;
        fill: #fff;
    }

    .p1 {
        stroke: #000;
    }

    .timeout-p1,
    .expired-p1 {
        stroke: #000;
    }

    .failure-circle {
        stroke: #fff;
    }

    .expired-circle,
    .timeout-circle {
        fill: #fff;
        stroke: #fff;
    }
}

.expired-circle,
.timeout-circle {
    stroke-width: 3px;
    stroke-linecap: round;
    stroke: $background-color-5;
    fill: $background-color-5;
    stroke-linejoin: round;
}

#expired-icon,
#timeout-icon {
    display: flex;
    margin-right: 8px;
    box-shadow: inset 0 0 0 $background-color-5;
    width: 30px;
    height: 30px;
    animation: scale 0.3s ease-in-out 0.9s both;
    stroke-width: 6px;
    stroke: #f8f8f8;
    stroke-miterlimit: 10;
}

.cb-container {
    display: flex;
    align-items: center;
    margin-left: 11px;
}

.logo-text {
    fill: #000;
}

#qr {
    fill: $color-1;

    svg {
        width: 40px;
        height: 40px;
    }
}

body.theme-dark {
    background-color: $background-color-3;
    color: $color-5;
}

#fr-helper-link,
#fr-helper-loop-link {
    display: block;
    color: $color-1;

    &:link,
    &:visited {
        display: block;
        color: $color-1;
    }

    &:active,
    &:hover,
    &:focus {
        color: $color-4;
    }
}

#expired-refresh-link,
#timeout-refresh-link {
    display: block;
    color: $color-1;

    &:link,
    &:visited {
        display: block;
        color: $color-1;
    }

    &:active,
    &:hover,
    &:focus {
        color: $color-4;
    }
}

html.rtl {
    direction: rtl;
}

.lang-es-es {
    .cb-lb-t {
        width: 115px;
    }

    #failure-msg {
        width: 153px;
    }

    #fr-helper {
        font-size: 12px;
    }

    .size-compact {
        .cb-lb-t {
            width: 74px;
        }

        #failure-msg {
            width: 85px;
        }
    }
}

.lang-da-dk {
    .cb-lb-t {
        width: 100px;
    }

    .size-compact {
        .cb-lb-t {
            padding-right: 30px;
            width: 65px;
        }
    }
}

.lang-de-de,
.lang-vi-vn,
.lang-bg-bg,
.lang-el-gr,
.lang-sv-se {
    #branding {
        display: flex;
        flex-direction: column;
        margin: 0 16px 0 0;
        padding-top: 5px;
        text-align: right;
    }

    .size-compact {
        #branding {
            display: flex;
            flex-flow: column nowrap;
            place-content: flex-end flex-start;
            align-items: flex-end;
            margin: 0;
            margin-top: 6px;
            margin-left: 16px;
            text-align: right;
        }

        .cb-lb-t {
            font-size: 10px;
        }

        #challenge-overlay,
        #challenge-error-text {
            line-height: 10px;
            font-size: 9px;
        }
    }

    #terms {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        line-height: 10px;
        font-style: normal;

        .link-spacer {
            display: none;
        }
    }

    #challenge-error {
        margin: 8px 4px;
    }
}

.lang-ja-jp {
    #branding {
        display: flex;
        flex-direction: column;
        margin: 0 16px 0 0;
        padding-top: 5px;
        text-align: right;
    }

    #terms {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        line-height: 10px;
        font-style: normal;

        .link-spacer {
            display: none;
        }
    }

    .cb-lb-t {
        font-size: 11px;
    }

    .size-compact {
        #challenge-overlay,
        #challenge-error-text {
            line-height: 10px;
        }
    }
}

.lang-ru-ru {
    #branding {
        display: flex;
        flex-direction: column;
        margin: 0 16px 0 0;
        padding-top: 5px;
        text-align: right;
    }

    .size-compact {
        #branding {
            display: flex;
            flex-flow: column nowrap;
            place-content: flex-end flex-start;
            align-items: flex-end;
            margin: 0;
            margin-top: 6px;
            margin-left: 16px;
            text-align: right;
        }

        #verifying-text {
            font-size: 10px;
        }

        .cb-lb-t {
            margin-left: 6px;
            font-size: 10px;
        }

        .cb-lb {
            .cb-i {
                left: 11px;
            }

            input {
                left: 11px;
            }
        }

        #challenge-overlay,
        #challenge-error-text {
            line-height: 10px;
            font-size: 8px;
        }
    }

    #terms {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        line-height: 10px;
        font-style: normal;

        .link-spacer {
            display: none;
        }
    }

    #challenge-error {
        margin: 8px 4px;
    }
}

.overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    opacity: 0.9;
    z-index: 2147483647;
    border: 1px solid $color-2;
    background-color: $background-color-4;
    padding: 2px;
    height: auto;
    line-height: 8px;
    color: $color-2;
    font-family: $font-family-mono;
    font-size: 8px;
}

.lang-it-it {
    .size-compact {
        #challenge-overlay,
        #challenge-error-text {
            line-height: 10px;
            font-size: 9px;
        }
    }
}

.lang-id-id {
    .size-compact {
        #challenge-overlay,
        #challenge-error-text {
            line-height: 10px;
        }
    }
}

@media (prefers-color-scheme: dark) {
    body.theme-auto {
        background-color: $background-color-3;
        color: $color-5;
    }

    .theme-auto {
        h1 {
            color: $color-5;
        }

        #challenge-error-title {
            color: $color-3;

            a {
                color: $color-6;

                &:visited,
                &:link {
                    color: $color-6;
                }

                &:hover,
                &:focus,
                &:active {
                    color: $color-7;
                }
            }
        }

        #challenge-overlay {
            background-color: $background-color-3;
        }

        #challenge-overlay,
        #challenge-error-text {
            color: $color-3;

            a {
                color: $color-6;

                &:visited,
                &:link {
                    color: $color-6;
                }

                &:hover,
                &:focus,
                &:active {
                    color: $color-7;
                }
            }
        }

        #terms {
            color: $color-6;

            a {
                color: $color-6;

                &:visited,
                &:link {
                    color: $color-6;
                }

                &:hover,
                &:focus,
                &:active {
                    color: $color-7;
                }
            }
        }

        #temrs {
            a {
                &:active {
                    color: $color-7;
                }
            }
        }

        #content {
            border-color: $border-color-2;
            background-color: $background-color-3;
        }

        #qr {
            fill: rgb(243 128 32);
        }

        .logo-text {
            fill: #fff;
        }

        .cb-lb {
            input {
                &:checked {
                    ~ {
                        .cb-i {
                            background-color: $background-color-5;

                            &::after {
                                border-color: $border-color-1;
                            }
                        }
                    }
                }

                &:active {
                    ~ {
                        .cb-i {
                            border: 2px solid $checkbox-color-1;
                        }
                    }
                }

                &:focus {
                    ~ {
                        .cb-i {
                            border: 2px solid $checkbox-color-1;
                        }
                    }
                }
            }

            .cb-i {
                border: 2px solid $dark-mark-color-1;
                background-color: $background-color-3;
            }
        }

        #success-icon {
            box-shadow: inset 0 0 0 $success-color-1;

            .p1 {
                box-shadow: inset 0 0 0 $success-color-1;
            }
        }

        .success-circle {
            stroke: $success-color-1;
            fill: $success-color-1;
        }

        #fr-helper-link,
        #fr-helper-loop-link {
            color: $color-6;

            &:visited,
            &:link {
                color: $color-6;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-7;
            }
        }

        #expired-refresh-link,
        #timeout-refresh-link {
            color: $color-6;

            &:visited,
            &:link {
                color: $color-6;
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-7;
            }
        }

        .overlay {
            border-color: $border-color-3;
            color: $color-3;
        }
    }
}
